import { ReactElement } from "react";
// import Logo from "@components/lawyerconsultation/logo";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import { setModal } from "@redux/shared/actions";
import Logo from "../logo";
import colors from "@styles/variables/lawyerconsultationVariables.module.scss";
import { useTranslation } from "@hooks/useTranslation";
// import LanguageSwitcher from "../languageSwitcher";
import { injectDoNotSellInFooter } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
export default function Footer(): ReactElement {
    const dispatch = useDispatch();
    const { pageType, visitDetails } = useDomainContext();
    const { translate } = useTranslation();

    const footerMenu = [
        {
            title: translate("TERMS_AND_CONDITIONS"),
            onClick: () =>
                dispatch(setModal({ active: true, content: "terms" })),
            href: undefined,
        },
        {
            title: translate("JOIN_US"),
            onClick: null,
            href: "join-us",
        },
        {
            title: translate("PRIVACY_POLICY"),
            onClick: () =>
                dispatch(setModal({ active: true, content: "privacy" })),
            href: undefined,
        },
        {
            title: translate("disclaimer"),
            onClick: () =>
                dispatch(setModal({ active: true, content: "disclaimer2" })),
            href: undefined,
        },
    ];
    injectDoNotSellInFooter({
        footerArray: footerMenu,
        pageType: pageType,
        trafficSource: visitDetails?.trafficSource,
    });
    return (
        <footer className={`${style.ftr} py-2 mt-16 relative`}>
            <div
                className={`${style.ftrContainer} max-w-screen-2xl mx-auto py-3 text-lg`}
            >
                <div
                    className={`${style.logo} mb-6 flex justify-between relative`}
                >
                    <Logo color={colors.lcPrimary} />
                    {/* <div className="hidden md:block absolute top-0 right-0">
                        <LanguageSwitcher />
                    </div> */}
                </div>
                <div
                    className={`${style.footerMenus} flex flex-col md:flex-row justify-between items-center`}
                >
                    <div
                        className={`flex justify-center md:justify-between capitalize flex-wrap`}
                    >
                        {footerMenu.map((item, idx) => {
                            return (
                                <a
                                    onClick={item.onClick}
                                    className={`${style.ftrLnk} block mr-5`}
                                    key={idx}
                                    href={item.href}
                                >
                                    {item.title}
                                </a>
                            );
                        })}
                    </div>
                    <div className={`${style.copyright}`}>
                        © {translate("COPYRIGHTS")} {new Date().getFullYear()}{" "}
                        {translate("ALL_RIGHTS_RESERVED")}
                    </div>
                </div>
            </div>
        </footer>
    );
}
